<template>
	<v-dialog v-model="visible" max-width="750" max-height="90%" height="700px" persistent>
		<W-loadable :content="mastertask" class="h-100">
			<v-card v-if="editMode" height="100%" class="d-flex flex-column scrolling">
				<div class="d-flex justify-space-between align-center modal-header">
					<h1>{{ mastertask?.id ? 'Upraviť master task' : 'Vytvoriť master task' }}</h1>
					<v-icon @click.stop="closeModal()" icon="mdi-close" size="large"></v-icon>
				</div>

				<v-card-actions class="d-flex flex-column modal-content">
					<v-autocomplete :custom-filter="customFilter" :match-any="true" v-model="mastertask.project_id" :label="$t('Project code')" :class="{error: v$.mastertask.project_id.$errors.length}" :items="projectsOptions" item-title="code" item-value="id" variant="solo" class="modal-input" clearable @update:search="projectCodeSearch = $event" />

					<v-text-field v-model="mastertask.name" @focus="onInputFocus" class="modal-input" label="Názov mastertasku" variant="solo" :class="{ error: v$.mastertask.name.$errors.length }" :hint="v$.mastertask.name.$errors ? errorUtils.parseErrors(v$.mastertask.name.$errors) : null"></v-text-field>

					<div class="splitted-inputs">
						<!-- TODO: Bialko validacie -->
						<Datepicker v-model="mastertask.deadline" :auto-apply="true" @focus="onDateFocus" :min-date="new Date()" class="modal-input left" format="dd.MM.yyyy" locale="sk" select-text="Vybrať" cancel-text="Zrušiť" placeholder="Dátum"
							:class="{ error: v$.mastertask.deadline.$errors?.length }" :validations="v$.mastertask.deadline" />

						<v-text-field class="modal-input right" variant="solo" v-model="mastertask._deadline_time" label="Čas" append-inner-icon="mdi-clock-outline" type="time"  @focus="onInputFocus"
						:class="{ error: v$.mastertask._deadline_time.$errors.length }" :hint="v$.mastertask._deadline_time.$errors ? errorUtils.parseErrors(v$.mastertask._deadline_time.$errors) : null" />
					</div>

					<v-text-field v-model="mastertask.duration" @focus="onInputFocus" class="modal-input" variant="solo" type="number" :label="`${mastertask.duration} h`" :disabled="true"></v-text-field>

					<v-select v-model="mastertask.team_id" @focus="onInputFocus" class="modal-input border-select" :class="{error: v$.mastertask.employees.$errors.length}" label="Tím" variant="solo" :items="teams" item-value="id" item-title="name" :disabled="mastertask.id"></v-select>

					<template v-if="!mastertask.id">
						<v-text-field v-for="(employee, index) in mastertask.employees" :key="employee.id" @focus="onInputFocus" class="modal-input" :class="{error: employee.invalid}" :label="`${employee.name} ${employee.surname}`" variant="solo" placeholder="h" v-model="mastertask.employees[index].duration" type="number" min="1" @input="onEmployeeDurationChanged">
							<p class="employee-select-avatar d-flex justify-center align-center">
								{{ employee?.name.charAt(0) + employee?.surname.charAt(0) }}
							</p>

							<v-icon @click.stop="removeEmployee(employee)" class="remove-input" icon="mdi-close" size="small"></v-icon>
						</v-text-field>
					</template>

					<!-- mastertask._allEmployees is used in edit to combine team employees with extra employees and subtask durations -->
					<template v-else>
						<v-text-field v-for="employee in mastertask._allEmployees" :key="employee.id" class="modal-input" :label="`${employee.name} ${employee.surname}`" variant="solo" placeholder="h" v-model="employee.duration" type="number" :disabled="true">
							<p class="employee-select-avatar d-flex justify-center align-center">
								{{ employee?.name.charAt(0) + employee?.surname.charAt(0) }}
							</p>

							<v-icon @click.stop="removeEmployee(employee)" class="remove-input" icon="mdi-close" size="small"></v-icon>
						</v-text-field>
					</template>

					<v-autocomplete :custom-filter="customFilter" :match-any="true" v-if="showAddEmployeeInput" :label="$t('Search for employee')" :class="{error: v$.mastertask.project_id.$errors.length}" :items="employeesOptions" item-title="_fullName" item-value="id" variant="solo" class="modal-input" clearable @update:model-value="onEmployeeSelected" />

					<!-- <Z-autocomplete label="Vyhľadať zamestnanca"  :options="employeesOptions" class="modal-input" @option-selected="onEmployeeSelected" /> -->

					<v-btn :disabled="mastertask.id" class="w-100 mb-5 button secondary" :class="{error: v$.mastertask.project_id.$errors.length}" @click="showAddEmployeeInput = true"> <v-icon class="mx-1" icon="mdi-plus" />Pridať zamestnanca </v-btn>

					<div class="w-100 modal-input" :class="{ error: v$.mastertask.description.$errors.length }">
						<QuillEditor ref="description" v-model="mastertask.description" placeholder="Popis" theme="snow" class="rich-text-editor" @keyup="onQuillEditorInput" />
					</div>

					<Z-files-upload class="mt-15 files" :files="mastertask.files" @files-uploaded="filesUploaded" :is-saving="isSaving" />
				</v-card-actions>

				<v-btn class="button primary" v-if="!isSaving" @click="save()">{{ mastertask.id ? 'ULOŽIŤ' : 'VYTVORIŤ' }}</v-btn>
				<v-btn class="button primary disabled" v-else disabled>{{ $t('Ukladám...')}}</v-btn>
			</v-card>

			<v-card v-else height="100%" class="d-flex flex-column scrolling">
				<div class="d-flex justify-space-between align-center">
					<p>{{ mastertask.project_code }}</p>
					<v-icon @click.stop="closeModal()" icon="mdi-close" size="large" class="pb-5"></v-icon>
				</div>
				<div class="d-flex justify-space-between align-center modal-header border-bottom pb-4">
					<h1>{{ mastertask.name }}</h1>
					<div class="d-flex justify-start">
						<img v-if="!mastertask.is_finished" class="icon" :src="require('@/plugins/app/_img/icons/pencil.svg')" alt="pencil-icon" @click.stop="editMode = !editMode" />
						<img class="icon trash" :src="require('@/plugins/app/_img/icons/bin.svg')" alt="trash-icon" @click.stop="openDeleteMasterTaskModal(mastertask)" />
					</div>
				</div>

				<v-card-actions class="d-flex flex-column modal-content">
					<div class="w-100">
						<A-mastertask-description :mastertask="mastertask" />

						<div class="d-flex justify-start align-center mt-8">
							<v-btn class="button primary" @click="toggleIsFinished(mastertask)">{{ mastertask.is_finished ? 'Otvoriť mastertask' : 'Ukončiť mastertask' }}</v-btn>
						</div>

						<A-tasks-table :subtasks="mastertask.subtasks" />

						<div class="d-flex align-center ">
							<v-btn class="subtask-btn w-100" @click="addTask()" ><v-icon class="mx-1" icon="mdi-plus" />Pridať sub task</v-btn>
						</div>
					</div>
				</v-card-actions>
			</v-card>
		</W-loadable>
	</v-dialog>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker'
import ZFilesUpload from '@/plugins/tm@_components/z-files-upload.vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import errorUtils from '@/plugins/lib@auth/_utils/error.utils'
// import ZAutocomplete from '@/plugins/tm@_components/z-autocomplete.vue'
import VuetifyFixFocusMixin from '@/plugins/tm@modal/_mixins/vuetify-fix-focus'
import moment from 'moment'
import { QuillEditor } from '@vueup/vue-quill'
// import tmUtils from '@/plugins/tm@_utils/tm.utils'
import modalMixin from '@/plugins/tm@modal/_mixins/modal.mixin'

import '@vueup/vue-quill/dist/vue-quill.snow.css'

import SelectsMixin from './_mixins/selects.mixin'
import ATasksTable from './_components/a-tasks-table.vue'
import AMastertaskDescription from './_components/a-mastertask-description.vue'
import { mapGetters } from 'vuex'
import AutocompleteMixin from '@/plugins/tm@_mixins/autocomplete.mixin'

export default {
	// MIXINS
	// computed: employeesOptions, projectsOptions
	// methods: onProjectSelected, onEmployeeSelected, onEmployeeDurationChanged
	// watch: mastertask.team_id

	emits: ['master-task-edited', 'close'],
	mixins: [VuetifyFixFocusMixin, SelectsMixin, AutocompleteMixin, modalMixin],

	components: {
		AMastertaskDescription,
		ATasksTable,
		Datepicker,
		ZFilesUpload,
		// ZAutocomplete,
		QuillEditor
	},

	data() {
		return {
			deadline: null,
			editMode: false,
			visible: false,
			errorUtils,
			v$: useVuelidate(),
			mastertask: null,
			isSaving: false,
			showAddEmployeeInput: false,
			date: null,
			projectCodeSearch: null
		}
	},

	computed: {
		...mapGetters('tm', [
			'teams',
			'projects',
			'enrichedEmployees'
		]),


		enrichedSubtasks() {
			if (!this.mastertask?.subtasks)
				return null

			return this.mastertask.subtasks.map(subtask => {
				const employee = this.enrichedEmployees.find(employee => employee.id == subtask.employee_id)

				return {
					...subtask,
					_initials: employee ? employee.name.charAt(0) + employee.surname.charAt(0) : '?',
					_fullName: employee ? `${employee.name} ${employee.surname}` : ''
				}
			})
		}
	},

	validations() {
		const quillEditorRequired = (value) => {
			if (!value || !value.length || !value.replace(/<[^>]*>/g, '').length)
				return false

			return true
		}

		return {
			mastertask: {
				employees: { required, minLength: minLength(1), $each: {duration: { required}} },
				project_id: { required },
				name: { required },
				description: { quillEditorRequired },
				deadline: { required },
				_deadline_time: { required }
			}
		}
	},

	mounted() {
		this.$events.on('close-both-modals', this.closeBothModals)

		this.$events.on('master-task:team-member-deleted', ({ teamId, memberId }) => {
			if (this.mastertask.team_id != teamId)
				return

			this.mastertask.employees = this.mastertask.employees.filter(employee => employee.id != memberId)
		}),

		this.$events.on('on-mastertask-deleted', (mastertask) => {
			if (mastertask?.id == this.mastertask?.id) this.closeModal()
		})

		this.$events.on('on-subtask-deleted', (task) => {
			if (!this.mastertask) return
			this.mastertask.subtasks = this.mastertask.subtasks.filter((subtask) => subtask.id != task.id)
		})

		this.$events.on('on-subtask-edited', (task) => {
			if (!this.mastertask?.subtasks) return

			if (this.mastertask.subtasks.find(subtask => subtask.id == task.id)) {
				this.mastertask.subtasks = this.mastertask.subtasks.map(t => t.id == task.id ? task : t)
			} else {
				this.mastertask.subtasks.push(task)
			}
		})
	},

	beforeUnmount() {
		this.$events.off('on-mastertask-deleted')
		this.$events.off('on-subtask-deleted')
		this.$events.off('on-subtask-edited')
		this.$events.off('master-task:team-member-deleted')
		this.$events.off('close-both-modals', this.closeBothModals)
	},

	methods: {
		closeBothModals() {
			this.$emit('close')
			this.visible = false
		},

		removeEmployee({ id }) {
			// const isEmployeeInTeam = this.mastertask.team?.employees.find(employee => employee.id == id)

			// if (isEmployeeInTeam) {
			// 	this.mastertask.team_id = null
			// 	this.mastertask.team = null
			// }

			this.mastertask.employees = this.mastertask.employees.filter(employee => employee.id != id)
		},

		removeMemberAlert(member) {
			const team = this.mastertask.team
			this.$events.emit('show-delete-team-member-modal', { member, team })
		},

		async showModal({mastertask, editMode = false}) {
			this.isSaving 	= false
			this.editMode 	= null
			this.visible 	= true
			this.mastertask = null
			this.$nextTick(() => {
				this.setFocusEvent()
				this.v$.$reset()
			})

			try {
				if (mastertask?.id) {
					this.mastertask = await this.$axios.get_auth_data(`v1/mastertasks/${mastertask.id}`)

					// TODO zmazat ked zacne prichadzat project_id a team_id
					const project = this.projects.find((project) => project.code == this.mastertask.project_code)
					this.mastertask.project_id 		= project?.id || null
					this.mastertask.team_id 		= this.mastertask.team?.id || null

					this.mastertask._allEmployees = this.mastertask.subtasks.map(({ employee_id, duration}) => {
						const employee = this.enrichedEmployees.find(({ id }) => id == employee_id)
						employee.duration = duration

						return employee
					})

					// this.mastertask.project_code 	= project.code
				} else {
					const savedMastertask = localStorage.getItem('mastertaskData')
					try {
						if (savedMastertask) {
							const parsedMastertask = JSON.parse(savedMastertask)
							this.mastertask = parsedMastertask
							// If the parsed result is not an object, reset to default
							if (typeof this.mastertask !== 'object' || this.mastertask === null) {
								throw new Error('Parsed mastertask is not an object')
							}
							this.$nextTick(() => {
								this.$refs['description']?.setHTML(this.mastertask.description || '');
							})
						} else {
							throw new Error('mastertaskData is null or undefined')
						}
					} catch (error) {
						console.error('Error parsing mastertaskData:', error);
						this.mastertask = { employees: [], duration: 0, _deadline_time: '17:00' }
					}
				}

				this.editMode = editMode || !this.mastertask.id
			} catch (error) {
				this.$toast.error(error.message || error)

				if (process.env.NODE_ENV === 'development')
					console.log('[z-master-task-modal.vue] Error: ', error)
			}
		},

		toggleIsFinished() {
			this.$events.emit('show-complete-master-task-modal', { mastertask: this.mastertask })
		},

		async save() {
			if (this.mastertask.id) {
				this.mastertask.employees = this.mastertask.employees.map(employee => {
					const { duration } = this.mastertask.subtasks.find(({ employee_id }) => employee_id == employee.id) || {}
					employee.duration = duration
					return employee
				})
			}

			const vuelidateValid = await this.v$.$validate()
			const employeesValid = await this._validateEmployees()

			if (!vuelidateValid || !employeesValid) return

			if (this.$refs['description'])
				this.mastertask.description = this.$refs['description'].getHTML()

			this._mergeDeadlineTimeDate()

			try {
				this.isSaving = true
				this.mastertask.deadline = moment(this.mastertask.deadline).format('YYYY-MM-DD HH:MM:SS')
				if (this.mastertask.id) await this.$axios.patch_auth_form(`v1/mastertasks/${this.mastertask.id}`, this.mastertask)
				else await this.$axios.post_auth_data('v1/mastertasks', this.mastertask)
				localStorage.removeItem('mastertaskData')

				this.$emit('master-task-edited', this.mastertask)
				this.closeModal()
			} catch (error) {
				this.$toast.error(this.$parseError(error))

				if (process.env.NODE_ENV === 'development')
					console.log('[z-master-task-modal.vue] Error: ', error)
			} finally {
				this.isSaving = false
			}
		},

		filesUploaded(files) {
			this.mastertask.files = files
		},

		closeModal() {
			this.visible = false
		},

		openDeleteMasterTaskModal(mastertask) {
			this.$events.emit('show-delete-master-task-modal', { mastertask })
		},

		addTask() {
			this.$events.emit('show-subtask-modal', {
				subtask: {
					master_task_id: this.mastertask.id,
					project_code: this.mastertask.project_code
				}
			})
		},

		onQuillEditorInput() {
			this.mastertask.description = this.$refs['description'].getHTML()
		},

		_mergeDeadlineTimeDate() {
			const date = new Date(this.mastertask.deadline)
			console.log('z-master-task-modal', this.subtask)
			const timeParts = this.mastertask._deadline_time.split(':')
			date.setHours(parseInt(timeParts[0]))
			date.setMinutes(parseInt(timeParts[1]))

			this.mastertask.deadline = date
		},

		_validateEmployees() {
			let isValid = true

			this.mastertask.employees = this.mastertask.employees.map(employee => {
				if (!employee.duration) {
					employee.invalid = true
					isValid = false
				}

				return employee
			})

			return isValid
		}
	},

	watch: {
		editMode() {
			this.$nextTick(() => {
				this.$refs['description']?.setHTML(this.mastertask.description || '')

				if (!this.mastertask?.id) return

				const date = new Date(this.mastertask.deadline)
				const hours = date.getHours()
				const minutes = date.getMinutes()

				this.mastertask._deadline_time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
			})
		},

		mastertask: {
			handler(val) {
				if (this.editMode && !this.mastertask?.id) {
					localStorage.setItem('mastertaskData', JSON.stringify(val))
				}
			},
			deep: true
		}
	}
}
</script>

<style lang="sass" scoped>
.date-wrap
	display: flex
	flex-flow: row
	width: 100%
	gap: 20px

	.dp__main
		width: calc(50% - 10px) !important

.employee-select-avatar
	background: #6200EE
	color: #fff
	border-radius: 50%
	padding: 13px
	font-size: 11.5px
	height: 24px
	width: 24px
	position: absolute
	top: 50%
	right: 0
	transform: translate(-120%, -50%)

.remove-input
	position: absolute
	top: 50%
	transform: translate(120%, -50%)
	cursor: pointer

.scrolling
	overflow: hidden !important

.border-bottom
	border-bottom: 1px solid rgba(0,0,0,.1)

.subtask-btn
	border: thin solid rgba(var(--v-border-color), var(--v-border-opacity)) !important
	color: #7f39fb !important
	height: 53px
	text-align: center
</style>
